<template>
  <v-dialog :value="value" persistent @click:outside="close" max-width="600px">
    <v-card>
      <v-toolbar dense>
        <v-toolbar-title>
          <v-card-title>
            Warnings / Errors
          </v-card-title>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-2 pb-1">
        <v-alert v-if="selectedContract.warning_reason" class="mt-3 mb-4" color="orange lighten-3">
          <v-row>
            <v-icon class="mx-4">mdi-alert</v-icon>
            Warning: {{ selectedContract.warning_reason }}
          </v-row>
          <v-row>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined class="mt-4" @click="downloadWarningLog">
                  Download Log
                  <v-icon right>
                    mdi-file-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Download warning log file</span>
            </v-tooltip>
            <v-spacer />
          </v-row>
        </v-alert>
        <v-alert v-if="selectedContract.failure_reason" color="red lighten-3">
          <v-row>
            <v-icon class="mx-4">mdi-close-box</v-icon> Error: {{ selectedContract.failure_reason }}
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
export default {
  name: 'ContractErrorsModal',
  mixins: [displayAlert, utils],
  props: {
    value: Boolean,
    selectedContract: Object
  },
  data: () => ({}),
  methods: {
    close() {
      this.$emit('closeErrors')
    },
    async downloadWarningLog () {
      try {
        const { data } =  await ContractUpload.downloadWarningLog(this.selectedContract.upload_id)
        if (data) {
          location.href = data.url
        }
      } catch (err) {
        this.handleError(err)
      }
    },
  }
}
</script>